import React, {useState} from 'react'
import { FormattedMessage} from '../../../../util/reactIntl';

import adventure from '../../../../assets/icons/characteristics/adventure.webp'
import Animals from '../../../../assets/icons/characteristics/Animals.webp'
import barrierefreiheit from '../../../../assets/icons/characteristics/barrierefreiheit.webp'
import Beach from '../../../../assets/icons/characteristics/Beach.webp'
import Bus from '../../../../assets/icons/characteristics/Bus.webp'
import Family from '../../../../assets/icons/characteristics/Family.webp'
import Komfort from '../../../../assets/icons/characteristics/Komfort.webp'
import Nature from '../../../../assets/icons/characteristics/Nature.webp'
import Projektbesuch_ from '../../../../assets/icons/characteristics/Projektbesuch_.webp'
import Projektbesuch_Cancel from '../../../../assets/icons/characteristics/Projektbesuch_Cancel.webp'
import Sport from '../../../../assets/icons/characteristics/Sport.webp'
import Theatre from '../../../../assets/icons/characteristics/Theatre.webp'
import Trekking from '../../../../assets/icons/characteristics/Trekking.webp'

import css from './Features.module.css'

const Features = ({features}) => {
    const [selectedFeatures , setSelectedFeatures] = useState(features)

    const specificValues = [
        {
          value: 'comfort',
          text:   <FormattedMessage id="filter.characteristics.comfort" />,
          icon: Komfort,
        },
        {
            value: 'adventure',
            text:   <FormattedMessage id="filter.characteristics.adventure" />,
            icon: adventure,
          },

          {
            value: 'nature',
            text:   <FormattedMessage id="filter.characteristics.nature" />,
            icon: Nature,
          },

          {
            value: 'near_beach',
            text:   <FormattedMessage id="filter.characteristics.near_beach" />,
            icon: Beach,
          },

          {
            value: 'animallovers',
            text:   <FormattedMessage id="filter.characteristics.animallovers" />,
            icon: Animals,
          },

          {
            value: 'sportlovers',
            text:   <FormattedMessage id="filter.characteristics.sportlovers" />,
            icon: Sport,
          },

          {
            value: 'familyfriendly',
            text:   <FormattedMessage id="filter.characteristics.familyfriendly" />,
            icon: Family,
          },

          {
            value: 'trekking',
            text:   <FormattedMessage id="filter.characteristics.trekking" />,
            icon: Trekking,
          },

          {
            value: 'culture',
            text:   <FormattedMessage id="filter.characteristics.culture" />,
            icon: Theatre,
          },

          {
            value: 'barrier_free',
            text:   <FormattedMessage id="filter.characteristics.barrier_free" />,
            icon: barrierefreiheit,
          },

          {
            value: 'accessibility',
            text:   <FormattedMessage id="filter.characteristics.accessibility" />,
            icon: Bus,
          },

          {
            value: 'projecttours_possible',
            text:   <FormattedMessage id="filter.characteristics.projecttours_possible" />,
            icon: Projektbesuch_,
          },

          {
            value: 'projecttours_impossible',
            text:   <FormattedMessage id="filter.characteristics.projecttours_impossible" />,
            icon: Projektbesuch_Cancel,
          },





        ]
        const selectedFeaturesSet = new Set(selectedFeatures);

        const elementsInBoth = [];
        const elementsOnlyInSpecific = [];

        specificValues.forEach((item) => {
          if (selectedFeaturesSet.has(item.value)) {
            elementsInBoth.push({
              text: item.text,
              icon: item.icon,
            });
          } else {
            elementsOnlyInSpecific.push({
              text: item.text,
              icon: item.icon,
            });
          }
        });
  return (
     <div className={css.featuresContainer} >
    <h3 className={css.title}><FormattedMessage id="EditListingWizard.tabLabelUSP" /></h3>
    <ul  className={css.featuresListContainer}>
      {elementsInBoth.map((item, index) => (
        <li className={css.features} key={index}>
          <span>{item.text}</span>
          <img className={css.featuresImg}  src={item.icon} alt ={item.text}/>
        </li>
      ))}
      </ul>
  </div>

  )
}

export default Features
