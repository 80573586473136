import React ,{useState} from 'react'

import dinnerPaid from '../../../../assets/icons/meals/Abendessen-paid.webp'
import dinner from '../../../../assets/icons/meals/Abendessen.webp'
import breakfastPaid from '../../../../assets/icons/meals/Breakfast-paid.webp'
import breakfast from '../../../../assets/icons/meals/Breakfast.webp'
import coffe from '../../../../assets/icons/meals/Coffee_.webp'
import lunchPaid from '../../../../assets/icons/meals/Lunch-paid.webp'
import lunch  from '../../../../assets/icons/meals/Lunch.webp'
import vegan from '../../../../assets/icons/meals/Vegan.webp'
import vegetarian from '../../../../assets/icons/meals/Vegetarian_.webp'
import { FormattedMessage} from '../../../../util/reactIntl';

import css from './Food.module.css'
const Food = ({food}) => {

    const specificValues = [
        {
          value: 'coffee_tee',
          text:   <FormattedMessage id="filter.food.coffee_tee" />,
          icon: coffe,
        },
        {
            value: 'vegetarian_options',
            text:   <FormattedMessage id="filter.food.vegetarian_options" />,
            icon: vegetarian,
          },
          {
            value: 'lunch_for_sale',
            text:   <FormattedMessage id="filter.food.lunch_for_sale" />,
            icon: lunchPaid,
          },
          {
            value: 'breakfast_for_sale',
            text:   <FormattedMessage id="filter.food.breakfast_for_sale" />,
            icon: breakfastPaid,
          },
          {
            value: 'dinner_for_sale',
            text:   <FormattedMessage id="filter.food.dinner_for_sale" />,
            icon: dinnerPaid,
          },
          {
            value: 'vegan_options',
            text:   <FormattedMessage id="filter.food.vegan_options" />,
            icon: vegan,
          },
          {
            value: 'dinner_inclusive',
            text:   <FormattedMessage id="filter.food.dinner_inclusive" />,
            icon: dinner,
          },
          {
            value: 'breakfast_inclusive',
            text:   <FormattedMessage id="filter.food.breakfast_inclusive" />,
            icon: breakfast,
          },
          {
            value: 'lunch_inclusive',
            text:   <FormattedMessage id="filter.food.lunch_inclusive" />,
            icon: lunch,
          },
        // Add more specific values, text, and icons as needed
      ];

      const transformedArray = food.reduce((result, item, index) => {
        const specific = specificValues.find((specificItem) => specificItem.value === item);
        if (specific) {
          result.push({
            text: specific.text,
            icon: specific.icon,
          });
        }
        return result;
      }, []);
      if (transformedArray.length === 0) {
        return null;
      }

  return (
    <div className={css.foodListingContainer} >
         <h3 className={css.title}>  <FormattedMessage id="EditListingOffersForm.foodTitle" /></h3>
       { transformedArray.map((item, index) => (
          <div className={css.foodListing} key={index}>
            <span>{item.text}</span>
            <img className={css.foodListingImg}  src={item.icon} alt ={item.text}/>
          </div>
        ))}
    </div>
  )
}

export default Food
