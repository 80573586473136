import React ,{useState} from 'react'
import { FormattedMessage} from '../../../../util/reactIntl';

import bathroomShared from '../../../../assets/icons/equipment/Bathroom-Shared.webp'
import bathroom from '../../../../assets/icons/equipment/Bathroom.webp'

import Bettwäsche from '../../../../assets/icons/equipment/Bettwäsche_.webp'
import Computer from '../../../../assets/icons/equipment/Computer.webp'
import Dresser from '../../../../assets/icons/equipment/Dresser.webp'
import Electricity from '../../../../assets/icons/equipment/Electricity.webp'
import Handtücher from '../../../../assets/icons/equipment/Handtücher_.webp'
import Heater from '../../../../assets/icons/equipment/Heater.webp'
import Kitchen from '../../../../assets/icons/equipment/Kitchen.webp'
import Klimaanlage from '../../../../assets/icons/equipment/Klimaanlage.webp'
import Lampe from '../../../../assets/icons/equipment/Lampe.webp'
import Luggage from '../../../../assets/icons/equipment/Luggage.webp'
import Moskito from '../../../../assets/icons/equipment/Moskito.webp'
import Parking from '../../../../assets/icons/equipment/Parking.webp'
import Security_Locker from '../../../../assets/icons/equipment/Security_Locker.webp'
import SharedKitchen from '../../../../assets/icons/equipment/SharedKitchen.webp'
import Shower from '../../../../assets/icons/equipment/Shower.webp'
import Sink from '../../../../assets/icons/equipment/Sink.webp'
import Toilet from '../../../../assets/icons/equipment/Toilet.webp'
import Tv from '../../../../assets/icons/equipment/Tv.webp'
import Ventilator_ from '../../../../assets/icons/equipment/Ventilator_.webp'
import Waschküche_ from '../../../../assets/icons/equipment/Waschküche_.webp'
import Wifi from '../../../../assets/icons/equipment/Wifi.webp'

import css from './Amenities.module.css'
const Amenities = ({amenities}) => {

    const [selectedAmenities , setSelectedAmenities] = useState(amenities)
    const specificValues = [
        {
          value: 'shared_bathroom',
          text:   <FormattedMessage id="filter.amenities.shared_bathroom" />,
          icon: bathroomShared,
        },
        {
            value: 'privat_bathroom',
            text:   <FormattedMessage id="filter.amenities.privat_bathroom" />,
            icon: bathroom,
          },
          
          {
            value: 'bedsheets',
            text:   <FormattedMessage id="filter.amenities.bedsheets" />,
            icon: Bettwäsche,
          },
          {
            value: 'computer',
            text:   <FormattedMessage id="filter.amenities.computer" />,
            icon: Computer,
          },
          {
            value: 'wardrobe',
            text:   <FormattedMessage id="filter.amenities.wardrobe" />,
            icon: Dresser,
          },
          {
            value: 'electricity',
            text:   <FormattedMessage id="filter.amenities.electricity" />,
            icon: Electricity,
          },
          {
            value: 'towels',
            text:   <FormattedMessage id="filter.amenities.towels" />,
            icon: Handtücher,
          },
          {
            value: 'heating',
            text:   <FormattedMessage id="filter.amenities.heating" />,
            icon: Heater,
          },
          {
            value: 'private_kitchen',
            text:   <FormattedMessage id="filter.amenities.private_kitchen" />,
            icon: Kitchen,
          },
          {
            value: 'air_conditioning',
            text:   <FormattedMessage id="filter.amenities.air_conditioning" />,
            icon: Klimaanlage,
          },
          {
            value: 'light',
            text:   <FormattedMessage id="filter.amenities.light" />,
            icon: Lampe,
          },
          {
            value: 'luggage_storage',
            text:   <FormattedMessage id="filter.amenities.luggage_storage" />,
            icon: Luggage,
          },
          {
            value: 'mosquito_net',
            text:   <FormattedMessage id="filter.amenities.mosquito_net" />,
            icon: Moskito,
          },
          {
            value: 'parking',
            text:   <FormattedMessage id="filter.amenities.parking" />,
            icon: Parking,
          },
          {
            value: 'safe',
            text:   <FormattedMessage id="filter.amenities.safe" />,
            icon: Security_Locker,
          },
          {
            value: 'shared_kitchen',
            text:   <FormattedMessage id="filter.amenities.shared_kitchen" />,
            icon: SharedKitchen,
          },
          {
            value: 'shower',
            text:   <FormattedMessage id="filter.amenities.shower" />,
            icon: Shower,
          },
          {
            value: 'sink',
            text:   <FormattedMessage id="filter.amenities.sink" />,
            icon: Sink,
          },
          {
            value: 'toilet',
            text:   <FormattedMessage id="filter.amenities.toilet" />,
            icon: Toilet,
          },
          {
            value: 'tv',
            text:   <FormattedMessage id="filter.amenities.tv" />,
            icon: Tv,
          },
          {
            value: 'fan',
            text:   <FormattedMessage id="filter.amenities.fan" />,
            icon: Ventilator_,
          },
          {
            value: 'laundry_facilities',
            text:   <FormattedMessage id="filter.amenities.laundry_facilities" />,
            icon: Waschküche_,
          },
          {
            value: 'internet',
            text:   <FormattedMessage id="filter.amenities.internet" />,
            icon: Wifi,
          },
        // Add more specific values, text, and icons as needed
      ];

      const selectedAmenitiesSet = new Set(selectedAmenities);

      const elementsInBoth = [];
      const elementsOnlyInSpecific = [];
      
      specificValues.forEach((item) => {
        if (selectedAmenitiesSet.has(item.value)) {
          elementsInBoth.push({
            text: item.text,
            icon: item.icon,
          });
        } else {
          elementsOnlyInSpecific.push({
            text: item.text,
            icon: item.icon,
          });
        }
      });

  return (
    <>
      <div className={css.amenitiesContainer} >
      <h3 className={css.title}><FormattedMessage id="EditListingFeaturesForm.featuresTitle" /></h3>
      <ul  className={css.amenitiesListContainer}> 
        {elementsInBoth.map((item, index) => (
          <li className={css.amenities} key={index}>
            <span>{item.text}</span>
            <img className={css.amenitiesImg}  src={item.icon} alt ={item.text}/> 
          </li>
        ))}
        </ul> 
    </div>

  
 
    </>
  
  )
}

export default Amenities