import React, { useEffect, useState, useCallback, useMemo } from 'react';
import css from './ListingSuggestions.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { searchListingsSuggestion } from '../../../SearchPage/SearchPage.duck';
import { createSlug, parse } from '../../../../util/urlHelpers';
import getCountryCodes from '../../../../translations/countryCodes';
import { FormattedMessage } from '../../../../util/reactIntl';
import Pin_Icon from '../../../../assets/Pin_Icon.svg';
import arrowLeft from '../../../../assets/icons/arrows/leftArrowLite.svg';
import arrowRight from '../../../../assets/icons/arrows/rightArrowLite.png';
import PrimaryButton from '../../../../components/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import Swipe from 'react-easy-swipe';
import DiscoverListings from './discoverListingSuggestions';
import NamedLinkNewTab from '../../../../components/NamedLink/NamedLinkNewTab';
import { locationBounds } from '../../../../util/googleMaps';

const SectionListingSuggestions = ({ currentListing }) => {
  const dispatch = useDispatch();
  const [suggestedListings, setSuggestedListings] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [index, setIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();
  const [width, setWidth] = useState(0);
  const { lang } = useParams();
  const countryCodes = getCountryCodes(lang == 'en' ? 'en ' : 'de');
  const countryCode = currentListing?.attributes?.publicData?.country ?? '';

  function findCountryNameByCode(countryCode) {
    const country = countryCodes.find(c => c.code === countryCode);
    return country ? country.name : 'Country not found';
  }

  const countryName = findCountryNameByCode(countryCode);
  const listingOrigin = currentListing.attributes.geolocation;
  const search = `address=${countryName}&origin=${listingOrigin.lat}%2C${listingOrigin.lng}`;

  const queryParams = parse(search, {
    latlng: ['origin'],
  });

  let searchParams = {
    page: 1,
    perPage: 40,
    include: ['author', 'images'],
    'fields.listing': [
      'title',
      'geolocation',
      'description',
      'price',
      'publicData.roomtype',
      'publicData.category',
      'publicData.city',
      'publicData.country',
      'publicData.amenities',
      'publicData.customCurrency',
    ],
    'fields.image': [
      'variants.scaled-small',
      'variants.landscape-crop',
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 760) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);

  const searchResultsListings = useSelector(state => state.SearchPage.searchResults);

  useEffect(() => {
    if (countryCode) {
      dispatch(searchListingsSuggestion({ ...searchParams, ...queryParams }));
    }
  }, [countryCode, dispatch]);

  const currencyCheck = useCallback((customCurrency, shareTribePrice) => {
    if (customCurrency !== undefined) {
      if (customCurrency.includes('Euro')) {
        return shareTribePrice + '€';
      } else if (customCurrency.includes('Dollar')) {
        return shareTribePrice + '$';
      } else {
        return shareTribePrice;
      }
    }
  }, []);

  const listingsPayload = useCallback(
    searchResultsListings => {
      let searchListings = searchResultsListings?.listings;
      let searchImages = searchResultsListings?.images;
      let listingsPayload = [];

      if (searchListings != null && searchImages != null) {
        Object.keys(searchListings).forEach(x => {
          const item = searchListings[x];
          if (
            item.id.uuid !== currentListing.id.uuid &&
            item.attributes.publicData.country === currentListing.attributes.publicData.country &&
            item.attributes.title.split('•')[0] !== currentListing.attributes.title.split('•')[0]
          ) {
            let firstImageId = item?.relationships?.images?.data[0] ?? '';
            let firstImagePayload = Object.values(searchImages).find(
              image => image.id.uuid === firstImageId.id.uuid
            );
            let customCurrency = item?.attributes?.publicData?.customCurrency ?? 'Euro';
            let formatPrice = currencyCheck(
              customCurrency,
              (item.attributes.price.amount / 100).toString()
            );
            let imgUrl =
              firstImagePayload.attributes.variants['landscape-crop6x']?.url ??
              firstImagePayload.attributes.variants['landscape-crop4x']?.url;
            let newListingObj = {
              title: item.attributes.title.split('•')[0],
              price: formatPrice,
              address:
                item.attributes.publicData.city +
                ', ' +
                findCountryNameByCode(item.attributes.publicData.country),
              id: item.id.uuid,
              link: '/l/' + createSlug(item.attributes.title) + '/' + item.id.uuid,
              linkParams: { slug: createSlug(item.attributes.title), id: item.id.uuid },
              imageLink: imgUrl,
            };
            listingsPayload.push(newListingObj);
          }
        });
        let filteredListings;
        if (removeDuplicatesKeepCheapest(listingsPayload)?.length >= 10) {
          filteredListings = removeDuplicatesKeepCheapest(listingsPayload).slice(0, 10);
        } else {
          filteredListings = removeDuplicatesKeepCheapest(listingsPayload);
        }
        setSuggestedListings(filteredListings);
        setShowSuggestions(true);
      }
    },
    [currentListing, currencyCheck, findCountryNameByCode]
  );

  const removeDuplicatesKeepCheapest = useCallback(listingsPayload => {
    const titlePriceMap = new Map();
    for (const listing of listingsPayload) {
      const { title, price } = listing;
      const priceValue = Number(price.replace(/[^0-9.-]+/g, ''));
      let rmvChars = price.replace(/[^0-9.,]/g, '');
      let replaceX = rmvChars.replace(',', '.');
      let rmvPoints = replaceX.replace(/^([^.]*)\.(.*)\./, '$1$2.');
      let roundedPrice = Math.ceil(rmvPoints);
      let finalPrice = `${roundedPrice}€`;

      if (!titlePriceMap.has(title) || priceValue < titlePriceMap.get(title).price) {
        titlePriceMap.set(title, { ...listing, price: finalPrice });
      }
    }
    return Array.from(titlePriceMap.values());
  }, []);

  const updateIndex = useCallback(
    delta => {
      setIndex(
        prevIndex => (prevIndex + delta + suggestedListings.length) % suggestedListings.length
      );
    },
    [suggestedListings]
  );

  const handlers = useMemo(
    () => ({
      increase: () => updateIndex(1),
      decrease: () => updateIndex(-1),
      swipeLeft: () => updateIndex(1),
      swipeRight: () => updateIndex(-1),
    }),
    [updateIndex]
  );

  const getClassName = useCallback(
    i => {
      const positionMap = {
        [(index - 1 + suggestedListings.length) % suggestedListings.length]: 0,
        [index]: 1,
        [(index + 1) % suggestedListings.length]: 2,
        [(index + 2) % suggestedListings.length]: 3,
        [(index + 3) % suggestedListings.length]: 4,
      };
      return positionMap.hasOwnProperty(i)
        ? `${css['slide']} ${css[`slide-${positionMap[i]}`]}`
        : css['hidden'];
    },
    [index, suggestedListings]
  );

  const renderSlide = useCallback(
    (listing, i) => (
      <div className={getClassName(i)} key={listing.id}>
        <div className={css.outerWrapper}>
          <NamedLinkNewTab
            name="ListingPage"
            params={listing?.linkParams}
            data-elbaction={`click:click accm-suglist-${listing?.title + listing?.adress}-uuid-${
              listing?.id
            }`}
          >
            <div className={css.innerWrapper}>
              <div className={css.imageWrapper}>
                <img className={css.cardImg} src={listing?.imageLink} alt="socialbnb projects" />
              </div>
              <div className={css.projectTitleWrapper}>
                <span className={css.projectName}>{listing?.title}</span>
                <div>
                  <span className={css.priceText}>
                    <FormattedMessage id="landing.accomodations.from" />
                  </span>
                  <span className={css.price}>{' ' + listing?.price}</span>
                  <span className={css.priceText}>
                    {' '}
                    <FormattedMessage id="BookingPanel.night" />
                  </span>
                </div>
              </div>
              <div className={css.projectLocationWrapper}>
                <img
                  src={Pin_Icon}
                  width="18px"
                  style={{ paddingRight: '2px' }}
                  alt="socialbnb projects characteristics"
                />
                <span className={css.location}>{listing.address}</span>
              </div>
            </div>
          </NamedLinkNewTab>
        </div>
      </div>
    ),
    [getClassName]
  );

  useEffect(() => {
    listingsPayload(searchResultsListings);
  }, [searchResultsListings]);

  if (!showSuggestions) {
    return (
      <div className={css.discoverContainer}>
        <div className={css.loadingContainer}>
          <div className={css.loading}></div>
        </div>
      </div>
    );
  } else if (suggestedListings?.length >= 5) {
    return (
      <div className={css.mainWrapper} data-elbaction="visible:vis accm-recommendations">
        <div className={css.sectionTitleWrapper}>
          <span className={css.sectionTitle}>
            <FormattedMessage id="sectionSuggestedListings.title" values={{ countryName }} />
          </span>
        </div>
        <div className={css.container}>
          <div className={css.sliderContainer}>
            <div className={css['slider-buttons']}>
              <img
                onClick={handlers.decrease}
                className={css['slider-button']}
                src={arrowLeft}
                alt="arrowLeft"
                data-elbaction="click: clicked swipeListingSuggestions"
              />
              <img
                onClick={handlers.increase}
                className={css['slider-button']}
                src={arrowRight}
                alt="arrowRight"
                data-elbaction="click: clicked swipeListingSuggestions"
              />
            </div>
            <div className={css.sliderCardsWrapper}>
              <Swipe
                onSwipeLeft={handlers.swipeLeft}
                onSwipeRight={handlers.swipeRight}
                allowMouseEvents
                tolerance={50}
                delta={10}
                preventDefaultTouchMoveEvent={false}
              >
                <div className={css['slider-cards']}>{suggestedListings.map(renderSlide)}</div>
              </Swipe>
            </div>
          </div>
        </div>
        <div className={css.btnWrapper} data-elbaction="click:click accm-backtosearchpage">
          <PrimaryButton
            className={css.backToSearchBtn}
            type="button"
            onClick={() => history.push(`/${lang}/s`)}
          >
            <FormattedMessage id="sectionSuggestedListings.backBtn" />
          </PrimaryButton>
        </div>
      </div>
    );
  } else {
    return <DiscoverListings currentListing={currentListing} openTab={true} />;
  }
};

export default SectionListingSuggestions;
