import { FormattedMessage } from 'react-intl';
import { extractDaysAndNights } from '../../../../../util/listingPages.util';

export const getGuestsLabel = (listing, intl) => {
  const guestsAmount = listing.attributes?.publicData?.projectPeopleAmount ?? 0;
  if (guestsAmount == 1) {
    return guestsAmount + ' ' + intl.formatMessage({ id: 'checkoutPage.oneGuest' });
  } else {
    return guestsAmount + ' ' + intl.formatMessage({ id: 'checkoutPage.manyGuests' });
  }
};

export const getTranslatedMDELabel = (listing, intl) => {
  const { days, nights } = extractDaysAndNights(listing?.attributes?.publicData?.MDE);

  if (days === 7) {
    return intl.formatMessage({ id: 'searchPage.experienceLabel1Week' });
  } else if (days === 14) {
    return intl.formatMessage({ id: 'searchPage.experienceLabel2Weeks' });
  } else if (days === 21) {
    return intl.formatMessage({ id: 'searchPage.experienceLabel3Weeks' });
  } else {
    return intl.formatMessage({ id: 'listingPage.experiencePackageCardLabel' }, { days, nights });
  }
};

export const getPricePer = roomType => {
  switch (roomType) {
    case 'shared_bedroom':
     return <FormattedMessage id="ListingCard.pricePerBed" />;
    case 'multi_bedroom':
    case 'singlebedroom':
    case 'twobedroom':
    case 'doublebedroom':
      return <FormattedMessage id="ListingCard.pricePerRoom" />;
    case 'tent':
      return <FormattedMessage id="ListingCard.pricePerTent" />;
    default:
      return '';
  }
};

export const currencyCheck = (customCurrency, shareTribePrice) => {
  if (customCurrency != undefined) {
    if (customCurrency.includes('Euro')) {
      return shareTribePrice;
    } else {
      return shareTribePrice;
    }
  } else {
    return shareTribePrice;
  }
};
