import React from 'react';
import { ReviewCard } from './ReviewCard';

export const ReviewsList = ({
  reviews,
  visibleCount,
  expanded,
  onExpand,
  isGoogleReview,
  currentLanguage,
}) => {
  const sortedReviews = [...reviews].sort((a, b) => {
    const ratingA = isGoogleReview ? b?.rating : b.attributes?.rating;
    const ratingB = isGoogleReview ? a?.rating : a.attributes?.rating;
    return ratingA - ratingB;
  });

  return sortedReviews
    .slice(0, visibleCount)
    .map((review, index) => (
      <ReviewCard
        key={isGoogleReview ? `google-${index}` : `review-${index}`}
        review={review}
        isGoogleReview={isGoogleReview}
        expanded={expanded[index]}
        onExpand={() => onExpand(index)}
        currentLanguage={currentLanguage}
      />
    ));
};
