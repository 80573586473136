import React from 'react';
import css from './SectionHeading.module.css';

const SectionHeading = props => {
  const { richTitle, isExperienceType, currentListing, currentLanguage } = props;
  const { experienceTitleDe, experienceTitle } = currentListing?.attributes?.publicData;
  const experienceTitleFinal =
    currentLanguage == 'en'
      ? experienceTitle
      : experienceTitleDe?.length > 0
      ? experienceTitleDe
      : experienceTitle;

  const stayTitleMaybe =
    currentLanguage == 'en'
      ? currentListing?.attributes?.publicData?.stayTitle
      : currentListing?.attributes?.publicData?.stayTitleDe;
  const stayTitleFinal = stayTitleMaybe ?? richTitle;

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h3 className={css.title}>{isExperienceType ? experienceTitleFinal : stayTitleFinal}</h3>
      </div>
    </div>
  );
};

export default SectionHeading;
