import { post } from '../../util/api';

export const getGoogleRating = l => {
  const lat = l.attributes.geolocation?.lat;
  const lng = l.attributes.geolocation?.lng;
  return post('/api/google-rating', { lat, lng })
    .then(resp => {
      if (resp.rating) {
        return resp.rating;
      } else {
        return null;
      }
    })
    .catch(e => {
      console.log(e);
      return null;
    });
};

export  function concatStrings(stringsArray) {
  if (stringsArray.length === 0) {
    return stringsArray;
  }
  return stringsArray.join('');
}
