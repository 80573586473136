import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../../util/reactIntl';
import { obfuscatedCoordinates } from '../../../../util/maps';
import { propTypes } from '../../../../util/types';
import { Map } from '../../../../components';
import config from '../../../../config';

import css from '../../ListingPage.module.css';

const MapDisplay = ({ className, geolocation, publicData, listingId }) => {
  const [isStatic, setIsStatic] = useState(true);

  if (!geolocation) {
    return null;
  }
  // console.log(geolocation);
  // console.log(listingId);
  // console.log(publicData);

  const address = publicData?.location?.address || '';

  const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

  const mapProps = config.maps.fuzzy.enabled
    ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
    : { address, center: geolocation };
  const map = <Map {...mapProps} useStaticMap={!isStatic} />;
  /* to change the zoom level on the map please go to the Map.js component and change the value at zoom: config.maps.fuzzy.enabled ? config.maps.fuzzy.defaultZoomLevel : 20, */
  return (
    <div className={css.sectionMap}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.locationTitle" />
      </h2>
      {/* {isStatic ? (
        <button
          className={css.map}
          onClick={() => {
            setIsStatic(false);
          }}
        >
          {map}
        </button>
      ) : (
        <div className={css.map}>{map}</div>
      )} */}

      <div className={css.map}>{map}</div>
    </div>
  );
};

MapDisplay.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

MapDisplay.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default MapDisplay;
