import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from '../../../../util/reactIntl';
import css from './ListingSuggestions.module.css';
import getCountryCodes from '../../../../translations/countryCodes';
import PrimaryButton from '../../../../components/Button/Button';
import SectionListingSuggestions from './ListingSuggestions';
import { useSelector } from 'react-redux';
import { locationBounds } from '../../../../util/googleMaps';
import { useParams } from 'react-router-dom';
/* import ExperiencesListingSlider from '../../ExperiencesSlider/ExperiencesListingSlider';
 */
import ExperiencesListingSliderListingPage from './experienceListingSuggestions/ExperienceListingSlider';

const DiscoverListings = ({ currentListing, openTab }) => {
  const currentLanguage = useSelector(state => state.ui.language);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();
  const [openTabDone, setOpenTab] = useState(false);
  const countryCodes = getCountryCodes(currentLanguage === 'en' ? 'en ' : 'de');
  const { lang } = useParams();

  const countryCode = currentListing?.attributes?.publicData?.country ?? '';

  const isExperienceType =
    currentListing?.attributes?.publicData?.listingType === 'impact-experience';

  const findCountryNameByCode = useCallback(
    code => {
      const country = countryCodes.find(c => c.code === code);
      return country ? country.name : 'Country not found';
    },
    [countryCodes]
  );

  const countryName = findCountryNameByCode(countryCode);

  const generateMapLink = useCallback(input => {
    if (input._sdkType === 'LatLngBounds' && input.ne && input.sw) {
      const ne = input.ne;
      const sw = input.sw;
      const bounds = `${ne.lat},${ne.lng},${sw.lat},${sw.lng}`;
      return `/${lang}/s?bounds=${encodeURIComponent(bounds)}&mapSearch=true`;
    } else {
      console.log('Error generating map link');
      return '';
    }
  }, []);

  const checkIfMobile = useCallback(() => {
    if (typeof window !== 'undefined') {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const mobileKeywords = [
        'android',
        'webos',
        'iphone',
        'ipad',
        'ipod',
        'blackberry',
        'windows phone',
      ];
      const isMobileDevice = mobileKeywords.some(keyword => userAgent.includes(keyword));
      const isSmallScreen = window.innerWidth < 700;
      return isMobileDevice || isSmallScreen;
    }
    return false;
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(checkIfMobile());
    };

    handleResize(); // Call it immediately to set the initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [checkIfMobile]);

  const openMapLink = useCallback(() => {
    if (openTab && currentListing) {
      const listingOrigin = currentListing.attributes.geolocation;
      const newMapLink = generateMapLink(locationBounds(listingOrigin, 600000));

      if (isMobile) {
        history.push(newMapLink);
      } else {
        window.open(newMapLink, '_blank');
      }
    }
  }, [openTab, currentListing, generateMapLink, isMobile, history]);

  useEffect(() => {
    openMapLink();
  }, [openMapLink]);

  const handleButtonClick = () => {
    setShowSuggestions(true);
    openMapLink();
  };

  if (!showSuggestions) {
    return (
      <div className={css.discoverContainer}>
        <div
          className={css.showSuggestionsButton}
          data-elbaction="click:click accm-openrecommondations"
        >
          <PrimaryButton
            className={css.showSuggestionsButton}
            type="button"
            onClick={handleButtonClick}
          >
            {isExperienceType ? (
              <FormattedMessage id="sectionSuggestedListings.showExperiencesSuggestions" />
            ) : (
              <FormattedMessage
                id="sectionSuggestedListings.showListingsSuggestions"
                values={{ countryName }}
              />
            )}
          </PrimaryButton>
        </div>
      </div>
    );
  } else if (isExperienceType) {
    return <ExperiencesListingSliderListingPage />;
  } else {
    return <SectionListingSuggestions currentListing={currentListing} />;
  }
};

export default DiscoverListings;
