import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Swipe from 'react-easy-swipe';
import css from './DifferentRoomtypesSlider.module.css';
import arrowLeft from '../../../../../assets/icons/arrows/leftArrowLite.svg';
import arrowRight from '../../../../../assets/icons/arrows/rightArrowLite.png';
import { createSlug } from '../../../../../util/urlHelpers';
import NamedLinkBlank from '../../../../../components/NamedLinkBlank/NamedLinkBlank';
import { fetchReviews } from '../../../ListingPage.duck';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatMoney, formatPriceFromUnitPurchase } from '../../../../../util/currency';
import socialbnbLogo from '../../../../../assets/logo-socialbnb-black.png';
import config from '../../../../../config/configDefault';
import IconReviewSearchPage from '../../ReviewRatings/IconReviewSearchPage/iconReviewSearchPage';
import { currencyCheck, getGuestsLabel, getPricePer, getTranslatedMDELabel } from './helpers';
import expIcon from '../../../../../assets/icons/baloon.webp';
import stayIcon from '../../../../../assets/icons/roomtypes/onebedroom.png';

const DifferentRoomtypesSlider = ({ listingsItems, currentListing, fetchReviews }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intl = useIntl();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 760) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateIndex = useCallback(
    newIndex => {
      if (listingsItems.length <= 2) {
        return;
      }

      if (listingsItems.length === 3 && isMobile) {
        if (newIndex < 0) {
          newIndex = listingsItems.length - 2;
        } else if (newIndex >= listingsItems.length) {
          newIndex = 0;
        }
      } else {
        if (newIndex < 0) {
          newIndex = listingsItems.length - 2;
        } else if (newIndex >= listingsItems.length - 1) {
          newIndex = 0;
        }
      }

      setCurrentIndex(newIndex);
    },
    [listingsItems]
  );

  const handlers = {
    onSwipeLeft: () => updateIndex(currentIndex + 1),
    onSwipeRight: () => updateIndex(currentIndex - 1),
  };

  if (!listingsItems || listingsItems.length === 0) return null;

  const getWrapperClassName = () => {
    if (listingsItems.length === 3) {
      return css.sliderWrapperThreeCards;
    }
    return css.sliderWrapper;
  };

  const getTransformValue = () => {
    if (listingsItems.length <= 2) {
      return 'translateX(0)';
    }

    const cardWidth = 280;
    const gap = 24;
    const slideWidth = cardWidth + gap;

    if (listingsItems.length === 3) {
      const threeCardsOffset = window.innerWidth <= 450 ? 20 : 0;
      return `translateX(calc(-${currentIndex * slideWidth}px + ${threeCardsOffset}px))`;
    }

    return `translateX(-${currentIndex * slideWidth}px)`;
  };

  const isExperienceType = l => l?.attributes?.publicData?.listingType == 'impact-experience';

  const getPriceData = price => {
    if (price && price.currency === config.currency) {
      const formattedPrice = formatMoney(intl, price);
      return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
      return {
        formattedPrice: intl.formatMessage(
          { id: 'ListingCard.unsupportedPrice' },
          { currency: price.currency }
        ),
        priceTitle: intl.formatMessage(
          { id: 'ListingCard.unsupportedPriceTitle' },
          { currency: price.currency }
        ),
      };
    }
    return {};
  };

  const scrollToTop = () => {
    if (typeof window !== 'undefined' && window?.document) {
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const renderSlides = () => {
    const slides = [...listingsItems];

    return slides.map((listing, i) => {
      const isExperience = isExperienceType(listing);
      const { price, publicData } = listing?.attributes;
      const { formattedPrice, priceTitle } = getPriceData(price);
      const pricePer = getPricePer(publicData?.roomtype);
      const finalReviews = publicData?.finalReviews || 0;
      const id = listing?.id?.uuid;
      const slug = createSlug(listing?.attributes?.title);

      const experienceTitle =
        intl.locale === 'en' ? publicData.experienceTitle : publicData.experienceTitleDe;
      const stayTitle = intl.locale === 'en' ? publicData.stayTitle : publicData.stayTitleDe;
      const title = isExperience
        ? experienceTitle
        : stayTitle
        ? stayTitle
        : listing.attributes?.title?.split('•')[0];

      // Check if title is longer than 16 characters
      const displayTitle = title && title.length > 34 ? `${title.slice(0, 34)}...` : title;

      return (
        <NamedLinkBlank name="ListingPage" params={{ id, slug }}>
          <div
            className={css.slide}
            key={`${listing.id.uuid}-${i}`}
            data-elbaction={`click:click roomtype-${listing.attributes.description}`}
            onClick={() => {
              scrollToTop();
            }}
          >
            <div
              className={
                id == currentListing?.id?.uuid
                  ? css.listingCardContainerSelected
                  : css.listingCardContainer
              }
            >
              {' '}
              {/* Icon and Text Badge */}
              <span className={css.topRightBadge}>
                <img
                  src={isExperience ? expIcon : stayIcon}
                  alt="Icon"
                  className={isExperience ? css.badgeIconExp : css.badgeIconStay}
                />
                <span className={css.badgeText}>
                  {isExperience ? (
                    <FormattedMessage id="MainPanelHeader.listingTypesFilter.Experience" />
                  ) : (
                    <FormattedMessage id="MainPanelHeader.listingTypesFilter.Stay" />
                  )}
                </span>
              </span>
              <img
                src={listing?.images[0]?.attributes?.variants['landscape-crop2x']?.url ?? ''}
                className={
                  id == currentListing?.id?.uuid ? css.listingImageSelected : css.listingImage
                }
              />
              <div className={css.listingInfo}>
                <div className={css.reviewRatings}>
                  <IconReviewSearchPage
                    value={finalReviews}
                    isListingPage={true}
                    reviewsAmount={{ value: '55555' }}
                  />
                </div>
                <div className={css.listingContent}>
                  <div className={css.listingTitle}>{displayTitle}</div>
                  <div className={css.listingDescription}>
                    <div className={css.listingDetails}>
                      <img src={socialbnbLogo} className={css.listingLogo} />
                      <div>
                        <div>
                          {isExperience
                            ? getTranslatedMDELabel(listing, intl)
                            : getGuestsLabel(listing, intl)}
                        </div>
                        {isExperience ? (
                          <div className={css.price}>
                            <FormattedMessage
                              id="listingPage.experiencePriceLabel"
                              values={{
                                value: formatPriceFromUnitPurchase(intl, price.amount),
                              }}
                            />
                          </div>
                        ) : (
                          <div className={css.price}>
                            <span className={css.priceValue} title={priceTitle}>
                              {currencyCheck(publicData.customCurrency, formattedPrice)}
                            </span>
                            <span className={css.priceSubTxt}>
                              /<FormattedMessage id="Slider.night" /> {pricePer}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </NamedLinkBlank>
      );
    });
  };

  return (
    <div
      className={
        listingsItems.length > 2 ? css.sliderContainerWrapper : css.sliderContainerAlignedLeft
      }
    >
      <div className={listingsItems.length > 2 ? css.sliderButtons : css.invisible}>
        <img
          onClick={() => updateIndex(currentIndex - 1)}
          className={css.sliderButton}
          src={arrowLeft}
          alt="Previous"
        />
        <img
          onClick={() => updateIndex(currentIndex + 1)}
          className={css.sliderButton}
          src={arrowRight}
          alt="Next"
        />
      </div>
      <Swipe
        className={css.sliderContainer}
        onSwipeLeft={handlers.onSwipeLeft}
        onSwipeRight={handlers.onSwipeRight}
      >
        <div className={getWrapperClassName()} style={{ transform: getTransformValue() }}>
          {renderSlides()}
        </div>
      </Swipe>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchReviews: (listingId, hostId, isActive) =>
    dispatch(fetchReviews(listingId, hostId, isActive)),
});

export default connect(null, mapDispatchToProps)(DifferentRoomtypesSlider);
