import React ,{useState} from 'react'

import bank from '../../../../assets/icons/surroundings/Bank.webp'
import doctor from '../../../../assets/icons/surroundings/Doctor.webp'
import restaurant from '../../../../assets/icons/surroundings/Restaurant.webp'
import supermarket from '../../../../assets/icons/surroundings/Supermarket.webp'
import turanbieter from '../../../../assets/icons/surroundings/Touranbieter.webp'
import wmachine from '../../../../assets/icons/equipment/Waschküche_.webp'
import bus from '../../../../assets/icons/characteristics/Bus.webp'


import { FormattedMessage} from '../../../../util/reactIntl';

import css from './Food.module.css'
const Surroundings = ({surroundings}) => {


    const [selectedSurroundings , setselectedSurroundings] = useState(surroundings)
    const specificValues = [
        {
          value: 'bank',
          text:   "Bank",
          icon: bank,
        },
        {
            value: 'facility',
            text:   <FormattedMessage id="filter.surroundings.facility" />,
            icon: doctor,
          },
          {
            value: 'restaurant',
            text:   <FormattedMessage id="filter.surroundings.restaurant" />,
            icon: restaurant,
          },
          {
            value: 'supermarket',
            text:   <FormattedMessage id="filter.surroundings.supermarket" />,
            icon: supermarket,
          },
          {
            value: 'tour',
            text:   <FormattedMessage id="filter.surroundings.tour" />,
            icon: turanbieter,
          },
          {
            value: 'washing',
            text:   <FormattedMessage id="filter.surroundings.washing" />,
            icon: wmachine,
          },
          {
            value: 'public_transport',
            text:   <FormattedMessage id="filter.surroundings.public_transport" />,
            icon: bus,
          },

        // Add more specific values, text, and icons as needed
      ];

      const transformedArray = selectedSurroundings.reduce((result, item, index) => {
        const specific = specificValues.find((specificItem) => specificItem.value === item);
        if (specific) {
          result.push({
            text: specific.text,
            icon: specific.icon,
          });
        }
        return result;
      }, []);
      if (transformedArray.length === 0) {
        return null;
      }

  return (
    <div className={css.foodListingContainer} >
        <h3 className={css.title}> <FormattedMessage id="EditListingOffersForm.surroundingsTitle" /></h3>
        {transformedArray.map((item, index) => (
          <div className={css.foodListing} key={index}>
            <span>{item.text}</span>
            <img className={css.foodListingImg}  src={item.icon} alt ={item.text}/>
          </div>
        ))}
    </div>
  )
}

export default Surroundings
